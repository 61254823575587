import React from "react";
import { MuiThemeProvider } from "./MuiThemeProvider";
import { StyledEngineProvider } from "@mui/material";
import AvistaApolloProvider from "./apollo";
import { MainContextProvider } from "./MainContext";

const AvistaProvider = ({ children }: React.PropsWithChildren) => (
  <MainContextProvider>
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider>
        {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
        <AvistaApolloProvider>{children}</AvistaApolloProvider>
        {/* </LocalizationProvider> */}
      </MuiThemeProvider>
    </StyledEngineProvider>
  </MainContextProvider>
);

export { AvistaProvider };
