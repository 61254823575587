import "./App.css";
import { AvistaProvider } from "./provider";
import { PageRouter } from "./router";

function App() {
  return (
    <AvistaProvider>
      <PageRouter />
    </AvistaProvider>
  );
}

export default App;
