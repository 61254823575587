import {
  responsiveFontSizes,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import React from "react";
import { theme } from "@avista-common/avista-material-ui-theme";

const responsiveFontSizesTheme = responsiveFontSizes(theme);
const MuiThemeProvider = ({ children }: React.PropsWithChildren) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={responsiveFontSizesTheme}>{children}</ThemeProvider>
  </StyledEngineProvider>
);

export { MuiThemeProvider };
