import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import { ErrorPage } from "../components/ErrorPage";
import { Layout } from "../layout";
import { ChatRoom } from "../pages/chatroom";

const PageRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Layout>
        <Routes>
          <Route path="/" element={<ChatRoom />}>
            <Route path="/:chatRoomId" element={<ChatRoom />} />
          </Route>
          <Route path="network-error" element={<ErrorPage />} />
        </Routes>
      </Layout>
    </BrowserRouter>
  );
};

export { PageRouter };
