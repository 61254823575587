import React from "react";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import { Theme } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import Toolbar from "@mui/material/Toolbar/Toolbar";
import { ChatRoomList } from "../components/chat-room/chat-room-list";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
  })
);

const NavDrawer: React.FC<
  DrawerProps & {
    setIsOpenDrawer: (value: React.SetStateAction<boolean>) => void;
  }
> = (props) => {
  const classes = useStyles();

  const { setIsOpenDrawer, ...drawerOnlyProps } = props;

  const drawerProps = Object.assign<DrawerProps, DrawerProps>(
    {
      className: classes.drawer,
      variant: "temporary",
      classes: {
        paper: classes.drawerPaper,
      },
    },
    drawerOnlyProps
  );

  return (
    <Drawer {...drawerProps}>
      <Toolbar />
      <ChatRoomList />
    </Drawer>
  );
};

export { NavDrawer };
