import { Avatar, Theme } from "@mui/material";
import { grey, lightBlue, yellow } from "@mui/material/colors";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import moment from "moment";
import { displayDateFormat } from "../../utils/const";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageRowLeft: {
      display: "flex",
    },
    messageRowRight: {
      display: "flex",
      justifyContent: "flex-end",
    },
    messageYellow: {
      backgroundColor: yellow[100],
      borderColor: yellow[600],
      "&:after": {
        borderTopColor: yellow[100],
      },
      "&:before": {
        borderTopColor: yellow[600],
      },
    },
    messageBlue: {
      backgroundColor: lightBlue[50],
      borderColor: lightBlue[400],
      "&:after": {
        borderTopColor: lightBlue[50],
      },
      "&:before": {
        borderTopColor: lightBlue[400],
      },
    },
    messageGrey: {
      backgroundColor: grey[200],
      borderColor: grey[400],
      "&:after": {
        borderTopColor: grey[200],
      },
      "&:before": {
        borderTopColor: grey[400],
      },
    },
    message: {
      position: "relative",
      margin: "15px",
      padding: "10px",
      width: "fit-content",
      font: "400 .9em 'Open Sans', sans-serif",
      textAlign: "left",
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "10px",
      maxWidth: "90%",
      "&:after": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTopWidth: "13px",
        borderTopStyle: "solid",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        top: "0",
      },
      "&:before": {
        content: "''",
        position: "absolute",
        width: "0",
        height: "0",
        borderTopWidth: "12px",
        borderTopStyle: "solid",
        borderLeft: "10px solid transparent",
        borderRight: "10px solid transparent",
        top: "-1px",
      },
    },
    messageLeft: {
      marginLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "15px",
      },
      "&:after": {
        left: "-15px",
        marginLeft: "6px",
      },
      "&:before": {
        left: "-17px",
        marginLeft: "6px",
      },
    },
    messageRight: {
      marginLeft: "20px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "15px",
      },
      marginRight: "20px",
      "&:after": {
        right: "-15px",
        marginRight: "6px",
      },
      "&:before": {
        right: "-17px",
        marginRight: "6px",
      },
    },

    messageContent: {
      padding: 0,
      margin: 0,
      fontSize: "13px",
      whiteSpace: "pre-line",
    },
    messageTimeStampRight: {
      fontSize: ".6em",
      fontWeight: "300",
      marginTop: "10px",
      float: "right",
    },
    avatar: {
      color: theme.palette.getContrastText(grey[200]),
      backgroundColor: grey[200],
      borderColor: grey[400],
      border: "1px solid",
    },
    avatarNothing: {
      color: "transparent",
      backgroundColor: "transparent",
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    displayName: {
      fontWeight: "bold",
    },
  })
);

enum MessagePosition {
  left = "left",
  right = "right",
}

enum MessageColor {
  grey = "grey",
  blue = "blue",
  yellow = "yellow",
}

interface IMessageProps {
  message: string;
  createdAt: Date;
  position: MessagePosition;
  displayName: string;
  color?: MessageColor;
  iconText?: string;
}

// Assistant
const Message = (props: IMessageProps) => {
  const classes = useStyles();

  const message = props.message ? props.message : "";
  const timestamp = props.createdAt
    ? moment(props.createdAt).format(displayDateFormat)
    : "unknown time";
  const displayName = props.displayName ? props.displayName : "-";

  let messaageColor: string = "";
  switch (props.color) {
    case MessageColor.blue:
      messaageColor = classes.messageBlue;
      break;
    case MessageColor.yellow:
      messaageColor = classes.messageYellow;
      break;
    default:
      messaageColor = classes.messageGrey;
  }
  const messagePosition =
    props.position === MessagePosition.left
      ? classes.messageLeft
      : classes.messageRight;

  const messageClasses = [classes.message, messagePosition, messaageColor].join(
    " "
  );
  const isLeftMessage = props.position === MessagePosition.left;
  return (
    <>
      <div
        className={
          isLeftMessage ? classes.messageRowLeft : classes.messageRowRight
        }
      >
        {isLeftMessage && (
          <Avatar alt={displayName} className={classes.avatar}>
            {props.iconText ?? null}
          </Avatar>
        )}
        <div>
          <div className={messageClasses}>
            <div className={classes.displayName}>{displayName}</div>
            <div>
              <article className={classes.messageContent}>
                {message.split("\n").map((p) => {
                  return <p>{p}</p>;
                })}
              </article>
            </div>
            <div className={classes.messageTimeStampRight}>{timestamp}</div>
          </div>
        </div>
        {!isLeftMessage && (
          <Avatar alt={displayName} className={classes.avatar}>
            {props.iconText ?? null}
          </Avatar>
        )}
      </div>
    </>
  );
};
export { Message, MessagePosition, MessageColor };
