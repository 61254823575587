import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";

enum ChatRoomFormDialogStatus {
  new = "new",
  edit = "edit",
}

interface ChatRoomFormDialogProps {
  defaultValue?: string;
  onSubmit: (value: string) => Promise<boolean>;
  mode?: ChatRoomFormDialogStatus;
  buttonProps?: React.ComponentProps<typeof IconButton>;
}

const ChatRoomFormDialog = ({
  defaultValue,
  mode,
  onSubmit,
  buttonProps,
}: ChatRoomFormDialogProps) => {
  const [open, setOpen] = React.useState(false);

  //TODO: apply react hook form if the form become more complex
  const nameRef = React.useRef<HTMLInputElement>(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const value = nameRef.current?.value;
    if (!value || !value.length) {
      return;
    }
    if (await onSubmit(value)) setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} {...buttonProps}>
        {mode === ChatRoomFormDialogStatus.edit ? (
          <EditIcon fontSize="small" />
        ) : (
          <AddIcon />
        )}
      </IconButton>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {mode === ChatRoomFormDialogStatus.edit
            ? "Rename chat room"
            : "New chat room"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Please input new chat room name</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Chat Room Name"
            fullWidth
            variant="standard"
            inputRef={nameRef}
            defaultValue={defaultValue}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { ChatRoomFormDialog, ChatRoomFormDialogStatus };
