import React from "react";
import { Button, TextField, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import SendIcon from "@mui/icons-material/Send";
import { isMobile } from "react-device-detect";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapForm: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      margin: `${theme.spacing(0)} auto`,
    },
    wrapText: {
      width: "100%",
    },
    button: {
      //margin: theme.spacing(1),
      borderRadius: "0",
    },
  })
);

export const ChatMessageInputForm = ({ sendMessage }) => {
  const classes = useStyles();
  const inputRef = React.createRef<HTMLInputElement>();

  const sendBtnClicked = () => {
    if (!inputRef.current) return;
    const text = inputRef.current?.value.trim();
    if (text.length === 0) return;
    sendMessage(text);
    inputRef.current.value = "";
  };

  const handleKeyDown = (e) => {
    // If press enter will send message
    // Mobile press enter will always start next line, so ignored.
    // If press shift+enter will start next line
    if (!isMobile && e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendBtnClicked();
    }
  };

  return (
    <>
      <form className={classes.wrapForm} noValidate autoComplete="off">
        <TextField
          id="standard-text"
          label="Please type message"
          placeholder={isMobile ? "" : "Press Shift+Enter to start new line"}
          className={classes.wrapText}
          inputRef={inputRef}
          multiline
          maxRows={10}
          onKeyDown={handleKeyDown}
        />
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={sendBtnClicked}
        >
          <SendIcon />
        </Button>
      </form>
    </>
  );
};
