import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Fab, Paper, Theme } from "@mui/material";
import { ChatMessageInputForm } from "./chat-input";
import { Message, MessageColor, MessagePosition } from "./chat-message";
import { useApi, useSnackbar } from "../../hooks";
import { ChatRoomRowFragment, MessageRole } from "../../generated/graphql";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getUserIconText, createdByToName } from "../../utils/helper";
import lodash from "lodash";
import moment from "moment";
import { displayDateFormat } from "../../utils/const";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      height: "calc(100vh - 100px)",
      width: "100vw",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      position: "relative",
    },
    messagesContainer: {
      width: "100%",
      margin: 0,
      overflowY: "scroll",
      height: "100%",
      padding: "20px",
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
      borderTop: "solid 1px grey",
    },
    stickyFab: {
      position: "absolute",
      top: "-50px",
      right: "20px",
      [theme.breakpoints.down("sm")]: {
        right: "10px",
      },
    },
    chatMessageDiv: { position: "relative", width: "100%" },
  })
);

interface ChatProps {
  chatRoomId: string;
  user: String;
}

export const Chat = (props: ChatProps) => {
  const classes = useStyles();
  const api = useApi();
  const [chatRoom, setChatRoom] = useState<ChatRoomRowFragment>();
  const [shouldSticky, setShouldSticky] = useState(true);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const { displaySnackbar } = useSnackbar();

  useEffect(
    function autoScrollWhenHaveMessages() {
      // if (shouldSticky) {
      scrollToBottom(false);
      // }
    },
    [chatRoom]
  );

  const scrollToBottom = (smooth: boolean = true) => {
    if (messagesContainerRef.current)
      messagesContainerRef.current?.scrollBy({
        left: 0,
        top: messagesContainerRef.current?.scrollHeight,
        behavior: smooth ? "smooth" : "auto",
      });
  };

  const handleScroll = useCallback(
    function autoSetSticky(e: React.UIEvent<HTMLDivElement>) {
      // if (messagesContainerRef.current)
      const scrollHeight = e.currentTarget.scrollHeight;
      const bottomScrollPosition =
        e.currentTarget.scrollTop + e.currentTarget.clientHeight; // from 0 to scrollHeight

      if (scrollHeight - bottomScrollPosition < 30) setShouldSticky(true);
      else setShouldSticky(false);
    },
    [setShouldSticky]
  );

  const initialChatHistory = useCallback(async () => {
    if (props.chatRoomId)
      await api.getChatRoomAndHistories({
        variables: {
          where: { id: props.chatRoomId },
        },
      });
  }, [api, props.chatRoomId]);

  useEffect(() => {
    initialChatHistory();
    scrollToBottom(false);
  }, [props.chatRoomId]);

  useEffect(
    function chatHistoryDataUpdated() {
      if (api.getChatRoomAndHistoriesQueryResult.data?.findUniqueChatRoom)
        setChatRoom(
          api.getChatRoomAndHistoriesQueryResult.data?.findUniqueChatRoom || []
        );
    },
    [api.getChatRoomAndHistoriesQueryResult.data]
  );

  useEffect(
    function chatHistoryDataUpdatedAfterSendChatResponse() {
      if (api.sendChatMutationResult.data?.sendChat?.id)
        setChatRoom(api.sendChatMutationResult.data?.sendChat);
    },
    [api.sendChatMutationResult.data]
  );

  const sendMessage = useCallback(
    async function sendMessage(message: string) {
      const tempChatRoom: ChatRoomRowFragment = lodash.cloneDeep(chatRoom);

      tempChatRoom.ChatHistories?.push({
        chatRoomId: props.chatRoomId,
        createdAt: new Date(),
        message: message,
        createdBy: props.user.toString(),
        id: moment().format(displayDateFormat), // generate temp id for frontend display message before request finish
        role: MessageRole.User,
      });
      setChatRoom(tempChatRoom);

      api
        .sendChat({
          variables: {
            data: {
              chatRoomId: props.chatRoomId,
              message: message,
            },
          },
        })
        .catch((err) => {
          console.log("err", err);
          displaySnackbar("error", err.message);
        });
    },
    [api, chatRoom, displaySnackbar, props.chatRoomId, props.user]
  );

  useEffect(
    function chatHistoriesError() {
      if (api.getChatRoomAndHistoriesQueryResult.error)
        displaySnackbar(
          "error",
          api.getChatRoomAndHistoriesQueryResult.error.message
        );
    },
    [api.getChatRoomAndHistoriesQueryResult.error, displaySnackbar]
  );

  return (
    <div className={classes.container}>
      {!props.chatRoomId && (
        <Paper className={classes.paper}>
          <h3>Please choose a chatroom</h3>
        </Paper>
      )}
      {props.chatRoomId && (
        <Paper className={classes.paper}>
          <h3>{chatRoom?.name}</h3>

          <div
            className={classes.messagesContainer}
            onScroll={handleScroll}
            ref={messagesContainerRef}
          >
            {chatRoom?.ChatHistories.map((chatHistory) => {
              const messageColor = chatHistory.createdBy
                ? chatHistory.createdBy === props.user
                  ? MessageColor.blue
                  : MessageColor.yellow
                : MessageColor.grey;

              const messagePosition =
                chatHistory.createdBy === props.user
                  ? MessagePosition.right
                  : MessagePosition.left;
              return (
                <Message
                  key={chatHistory.id}
                  message={chatHistory.message}
                  createdAt={new Date()}
                  displayName={createdByToName(chatHistory.createdBy)}
                  color={messageColor}
                  position={messagePosition}
                  iconText={getUserIconText(chatHistory.createdBy)}
                />
              );
            })}
          </div>
          <div className={classes.chatMessageDiv}>
            {!shouldSticky && (
              <Fab
                size="small"
                color="primary"
                className={classes.stickyFab}
                onClick={() => scrollToBottom()}
              >
                <KeyboardArrowDownIcon />
              </Fab>
            )}
            <ChatMessageInputForm sendMessage={sendMessage} />
          </div>
        </Paper>
      )}
    </div>
  );
};
