import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useApi, useSnackbar } from ".";
import { useMainContext } from "../reducer";
import {
  IReducerState,
  IReducerActions,
  ReducerActionType,
} from "../utils/interface";

const useChatRoomsList = () => {
  const api = useApi();
  const { displaySnackbar } = useSnackbar();

  // get reducer from context
  const {
    state,
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const setChatRooms = useCallback(
    function setChatRooms(newChatRooms) {
      dispatch({
        type: ReducerActionType.updateChatRooms,
        payload: { chatRooms: newChatRooms },
      });
    },
    [dispatch]
  );

  const navigate = useNavigate();
  const initialChatRooms = useCallback(async () => {
    await api.getChatRooms({
      // TODO: filter by keywords
      // variables: {
      //   keywords:"",
      // },
    });
  }, [api]);

  useEffect(() => {
    initialChatRooms();
  }, []);

  useEffect(
    function chatRoomsDataUpdated() {
      if (api.getChatRoomsQueryResult.data?.getChatRooms.total)
        setChatRooms(api.getChatRoomsQueryResult.data?.getChatRooms.data || []);
    },
    [api.getChatRoomsQueryResult.data, setChatRooms]
  );

  const createNewChatRoom = useCallback(
    async function createNewChatRoom(name: string): Promise<boolean> {
      const newChatRoom = await api
        .createChatRoom({
          variables: {
            data: {
              name,
            },
          },
        })
        .catch((err) => {
          console.log("err", err);
          displaySnackbar("error", err.message);
        });

      if (newChatRoom?.data?.createChatRoom) {
        displaySnackbar("success", "Created chat room successfully");
        // setChatRooms([...chatRooms, newChatRoom.data.createChatRoom]);
        initialChatRooms();

        navigate("/" + newChatRoom.data.createChatRoom.id);
        return true;
      }
      return false;
    },
    [api, displaySnackbar, initialChatRooms, navigate]
  );

  async function editChatRoom(id: string, name: string): Promise<boolean> {
    const updatedChatRoom = await api
      .updateChatRoom({
        variables: {
          data: {
            name: { set: name },
          },
          where: {
            id,
          },
        },
      })
      .catch((err) => {
        console.log("err", err);
        displaySnackbar("error", err.message);
      });

    console.log("updatedChatRoom", updatedChatRoom);
    if (updatedChatRoom?.data?.updateOneChatRoom) {
      displaySnackbar("success", "Updated chat room successfully");
      initialChatRooms();

      navigate("/" + updatedChatRoom.data.updateOneChatRoom.id);
      return true;
    }
    return false;
  }

  async function deleteChatRoom(id: string) {
    const deletedChatRoom = await api.deleteChatRoom({
      variables: {
        where: {
          id,
        },
      },
    });
    console.log("deleteChatRoom", deletedChatRoom);
    displaySnackbar("success", "Deleted chat room successfully");
    initialChatRooms();
  }

  return {
    chatRooms: state.chatRooms,
    editChatRoom,
    deleteChatRoom,
    createNewChatRoom,
  };
};

export { useChatRoomsList };
