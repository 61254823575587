import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  Json: any;
};

export type AggregateChatHistory = {
  _count?: Maybe<ChatHistoryCountAggregateOutputType>;
  _max?: Maybe<ChatHistoryMaxAggregateOutputType>;
  _min?: Maybe<ChatHistoryMinAggregateOutputType>;
};

export type AggregateChatRoom = {
  _count?: Maybe<ChatRoomCountAggregateOutputType>;
  _max?: Maybe<ChatRoomMaxAggregateOutputType>;
  _min?: Maybe<ChatRoomMinAggregateOutputType>;
};

export type BatchPayload = {
  count: Scalars['Int'];
};

export type ChatHistory = {
  ChatRoom: ChatRoom;
  chatRoomId: Scalars['String'];
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  message: Scalars['String'];
  role: MessageRole;
};

export type ChatHistoryCountAggregateOutputType = {
  _all: Scalars['Int'];
  chatRoomId: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdBy: Scalars['Int'];
  id: Scalars['Int'];
  message: Scalars['Int'];
  role: Scalars['Int'];
};

export type ChatHistoryCountOrderByAggregateInput = {
  chatRoomId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
};

export type ChatHistoryCreateInput = {
  ChatRoom: ChatRoomCreateNestedOneWithoutChatHistoriesInput;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  role: MessageRole;
};

export type ChatHistoryCreateManyChatRoomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  role: MessageRole;
};

export type ChatHistoryCreateManyChatRoomInputEnvelope = {
  data: Array<ChatHistoryCreateManyChatRoomInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ChatHistoryCreateManyInput = {
  chatRoomId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  role: MessageRole;
};

export type ChatHistoryCreateNestedManyWithoutChatRoomInput = {
  connect?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChatHistoryCreateOrConnectWithoutChatRoomInput>>;
  create?: InputMaybe<Array<ChatHistoryCreateWithoutChatRoomInput>>;
  createMany?: InputMaybe<ChatHistoryCreateManyChatRoomInputEnvelope>;
};

export type ChatHistoryCreateOrConnectWithoutChatRoomInput = {
  create: ChatHistoryUncheckedCreateWithoutChatRoomInput;
  where: ChatHistoryWhereUniqueInput;
};

export type ChatHistoryCreateWithoutChatRoomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  role: MessageRole;
};

export type ChatHistoryListRelationFilter = {
  every?: InputMaybe<ChatHistoryWhereInput>;
  none?: InputMaybe<ChatHistoryWhereInput>;
  some?: InputMaybe<ChatHistoryWhereInput>;
};

export type ChatHistoryMaxAggregateOutputType = {
  chatRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  role?: Maybe<MessageRole>;
};

export type ChatHistoryMaxOrderByAggregateInput = {
  chatRoomId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
};

export type ChatHistoryMinAggregateOutputType = {
  chatRoomId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  role?: Maybe<MessageRole>;
};

export type ChatHistoryMinOrderByAggregateInput = {
  chatRoomId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
};

export type ChatHistoryOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ChatHistoryOrderByWithAggregationInput = {
  _count?: InputMaybe<ChatHistoryCountOrderByAggregateInput>;
  _max?: InputMaybe<ChatHistoryMaxOrderByAggregateInput>;
  _min?: InputMaybe<ChatHistoryMinOrderByAggregateInput>;
  chatRoomId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
};

export type ChatHistoryOrderByWithRelationInput = {
  ChatRoom?: InputMaybe<ChatRoomOrderByWithRelationInput>;
  chatRoomId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  message?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
};

export enum ChatHistoryScalarFieldEnum {
  ChatRoomId = 'chatRoomId',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  Id = 'id',
  Message = 'message',
  Role = 'role'
}

export type ChatHistoryScalarWhereInput = {
  AND?: InputMaybe<Array<ChatHistoryScalarWhereInput>>;
  NOT?: InputMaybe<Array<ChatHistoryScalarWhereInput>>;
  OR?: InputMaybe<Array<ChatHistoryScalarWhereInput>>;
  chatRoomId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumMessageRoleFilter>;
};

export type ChatHistoryScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ChatHistoryScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ChatHistoryScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ChatHistoryScalarWhereWithAggregatesInput>>;
  chatRoomId?: InputMaybe<StringWithAggregatesFilter>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  createdBy?: InputMaybe<StringNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  message?: InputMaybe<StringWithAggregatesFilter>;
  role?: InputMaybe<EnumMessageRoleWithAggregatesFilter>;
};

export type ChatHistorySimpleCreateInput = {
  chatRoomId: Scalars['String'];
  message: Scalars['String'];
};

export type ChatHistoryUncheckedCreateInput = {
  chatRoomId: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  role: MessageRole;
};

export type ChatHistoryUncheckedCreateNestedManyWithoutChatRoomInput = {
  connect?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChatHistoryCreateOrConnectWithoutChatRoomInput>>;
  create?: InputMaybe<Array<ChatHistoryCreateWithoutChatRoomInput>>;
  createMany?: InputMaybe<ChatHistoryCreateManyChatRoomInputEnvelope>;
};

export type ChatHistoryUncheckedCreateWithoutChatRoomInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  role: MessageRole;
};

export type ChatHistoryUncheckedUpdateInput = {
  chatRoomId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumMessageRoleFieldUpdateOperationsInput>;
};

export type ChatHistoryUncheckedUpdateManyInput = {
  chatRoomId?: InputMaybe<StringFieldUpdateOperationsInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumMessageRoleFieldUpdateOperationsInput>;
};

export type ChatHistoryUncheckedUpdateManyWithoutChatHistoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumMessageRoleFieldUpdateOperationsInput>;
};

export type ChatHistoryUncheckedUpdateManyWithoutChatRoomNestedInput = {
  connect?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChatHistoryCreateOrConnectWithoutChatRoomInput>>;
  create?: InputMaybe<Array<ChatHistoryCreateWithoutChatRoomInput>>;
  createMany?: InputMaybe<ChatHistoryCreateManyChatRoomInputEnvelope>;
  delete?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChatHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<ChatHistoryUpdateWithWhereUniqueWithoutChatRoomInput>>;
  updateMany?: InputMaybe<Array<ChatHistoryUpdateManyWithWhereWithoutChatRoomInput>>;
  upsert?: InputMaybe<Array<ChatHistoryUpsertWithWhereUniqueWithoutChatRoomInput>>;
};

export type ChatHistoryUncheckedUpdateWithoutChatRoomInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumMessageRoleFieldUpdateOperationsInput>;
};

export type ChatHistoryUpdateInput = {
  ChatRoom?: InputMaybe<ChatRoomUpdateOneRequiredWithoutChatHistoriesNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumMessageRoleFieldUpdateOperationsInput>;
};

export type ChatHistoryUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumMessageRoleFieldUpdateOperationsInput>;
};

export type ChatHistoryUpdateManyWithWhereWithoutChatRoomInput = {
  data: ChatHistoryUncheckedUpdateManyWithoutChatHistoriesInput;
  where: ChatHistoryScalarWhereInput;
};

export type ChatHistoryUpdateManyWithoutChatRoomNestedInput = {
  connect?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ChatHistoryCreateOrConnectWithoutChatRoomInput>>;
  create?: InputMaybe<Array<ChatHistoryCreateWithoutChatRoomInput>>;
  createMany?: InputMaybe<ChatHistoryCreateManyChatRoomInputEnvelope>;
  delete?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  deleteMany?: InputMaybe<Array<ChatHistoryScalarWhereInput>>;
  disconnect?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  set?: InputMaybe<Array<ChatHistoryWhereUniqueInput>>;
  update?: InputMaybe<Array<ChatHistoryUpdateWithWhereUniqueWithoutChatRoomInput>>;
  updateMany?: InputMaybe<Array<ChatHistoryUpdateManyWithWhereWithoutChatRoomInput>>;
  upsert?: InputMaybe<Array<ChatHistoryUpsertWithWhereUniqueWithoutChatRoomInput>>;
};

export type ChatHistoryUpdateWithWhereUniqueWithoutChatRoomInput = {
  data: ChatHistoryUncheckedUpdateWithoutChatRoomInput;
  where: ChatHistoryWhereUniqueInput;
};

export type ChatHistoryUpdateWithoutChatRoomInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<NullableStringFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  message?: InputMaybe<StringFieldUpdateOperationsInput>;
  role?: InputMaybe<EnumMessageRoleFieldUpdateOperationsInput>;
};

export type ChatHistoryUpsertWithWhereUniqueWithoutChatRoomInput = {
  create: ChatHistoryUncheckedCreateWithoutChatRoomInput;
  update: ChatHistoryUncheckedUpdateWithoutChatRoomInput;
  where: ChatHistoryWhereUniqueInput;
};

export type ChatHistoryWhereInput = {
  AND?: InputMaybe<Array<ChatHistoryWhereInput>>;
  ChatRoom?: InputMaybe<ChatRoomWhereInput>;
  NOT?: InputMaybe<Array<ChatHistoryWhereInput>>;
  OR?: InputMaybe<Array<ChatHistoryWhereInput>>;
  chatRoomId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  message?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumMessageRoleFilter>;
};

export type ChatHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type ChatRoom = {
  ChatHistories: Array<ChatHistory>;
  _count: ChatRoomCountOutputType;
  createdAt: Scalars['DateTime'];
  createdBy: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  name: Scalars['String'];
};


export type ChatRoomChatHistoriesArgs = {
  cursor?: InputMaybe<ChatHistoryWhereUniqueInput>;
  distinct?: InputMaybe<ChatHistoryScalarFieldEnum>;
  orderBy?: InputMaybe<ChatHistoryOrderByWithRelationInput>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatHistoryWhereInput>;
};

export type ChatRoomCountAggregateOutputType = {
  _all: Scalars['Int'];
  createdAt: Scalars['Int'];
  createdBy: Scalars['Int'];
  deletedAt: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['Int'];
};

export type ChatRoomCountOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ChatRoomCountOutputType = {
  ChatHistories: Scalars['Int'];
};

export type ChatRoomCreateInput = {
  ChatHistories?: InputMaybe<ChatHistoryCreateNestedManyWithoutChatRoomInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ChatRoomCreateManyInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ChatRoomCreateNestedOneWithoutChatHistoriesInput = {
  connect?: InputMaybe<ChatRoomWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChatRoomCreateOrConnectWithoutChatHistoriesInput>;
  create?: InputMaybe<ChatRoomUncheckedCreateWithoutChatHistoriesInput>;
};

export type ChatRoomCreateOrConnectWithoutChatHistoriesInput = {
  create: ChatRoomUncheckedCreateWithoutChatHistoriesInput;
  where: ChatRoomWhereUniqueInput;
};

export type ChatRoomCreateWithoutChatHistoriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ChatRoomList = {
  data: Array<ChatRoom>;
  total: Scalars['Int'];
};

export type ChatRoomMaxAggregateOutputType = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ChatRoomMaxOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ChatRoomMinAggregateOutputType = {
  createdAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type ChatRoomMinOrderByAggregateInput = {
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ChatRoomOrderByWithAggregationInput = {
  _count?: InputMaybe<ChatRoomCountOrderByAggregateInput>;
  _max?: InputMaybe<ChatRoomMaxOrderByAggregateInput>;
  _min?: InputMaybe<ChatRoomMinOrderByAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ChatRoomOrderByWithRelationInput = {
  ChatHistories?: InputMaybe<ChatHistoryOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  createdBy?: InputMaybe<SortOrder>;
  deletedAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ChatRoomRelationFilter = {
  is?: InputMaybe<ChatRoomWhereInput>;
  isNot?: InputMaybe<ChatRoomWhereInput>;
};

export enum ChatRoomScalarFieldEnum {
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  DeletedAt = 'deletedAt',
  Id = 'id',
  Name = 'name'
}

export type ChatRoomScalarWhereWithAggregatesInput = {
  AND?: InputMaybe<Array<ChatRoomScalarWhereWithAggregatesInput>>;
  NOT?: InputMaybe<Array<ChatRoomScalarWhereWithAggregatesInput>>;
  OR?: InputMaybe<Array<ChatRoomScalarWhereWithAggregatesInput>>;
  createdAt?: InputMaybe<DateTimeWithAggregatesFilter>;
  createdBy?: InputMaybe<StringWithAggregatesFilter>;
  deletedAt?: InputMaybe<DateTimeNullableWithAggregatesFilter>;
  id?: InputMaybe<StringWithAggregatesFilter>;
  name?: InputMaybe<StringWithAggregatesFilter>;
};

export type ChatRoomSimpleCreateInput = {
  name: Scalars['String'];
};

export type ChatRoomUncheckedCreateInput = {
  ChatHistories?: InputMaybe<ChatHistoryUncheckedCreateNestedManyWithoutChatRoomInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ChatRoomUncheckedCreateWithoutChatHistoriesInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  createdBy: Scalars['String'];
  deletedAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type ChatRoomUncheckedUpdateInput = {
  ChatHistories?: InputMaybe<ChatHistoryUncheckedUpdateManyWithoutChatRoomNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ChatRoomUncheckedUpdateManyInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ChatRoomUncheckedUpdateWithoutChatHistoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ChatRoomUpdateInput = {
  ChatHistories?: InputMaybe<ChatHistoryUpdateManyWithoutChatRoomNestedInput>;
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ChatRoomUpdateManyMutationInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ChatRoomUpdateOneRequiredWithoutChatHistoriesNestedInput = {
  connect?: InputMaybe<ChatRoomWhereUniqueInput>;
  connectOrCreate?: InputMaybe<ChatRoomCreateOrConnectWithoutChatHistoriesInput>;
  create?: InputMaybe<ChatRoomUncheckedCreateWithoutChatHistoriesInput>;
  update?: InputMaybe<ChatRoomUncheckedUpdateWithoutChatHistoriesInput>;
  upsert?: InputMaybe<ChatRoomUpsertWithoutChatHistoriesInput>;
};

export type ChatRoomUpdateWithoutChatHistoriesInput = {
  createdAt?: InputMaybe<DateTimeFieldUpdateOperationsInput>;
  createdBy?: InputMaybe<StringFieldUpdateOperationsInput>;
  deletedAt?: InputMaybe<NullableDateTimeFieldUpdateOperationsInput>;
  id?: InputMaybe<StringFieldUpdateOperationsInput>;
  name?: InputMaybe<StringFieldUpdateOperationsInput>;
};

export type ChatRoomUpsertWithoutChatHistoriesInput = {
  create: ChatRoomUncheckedCreateWithoutChatHistoriesInput;
  update: ChatRoomUncheckedUpdateWithoutChatHistoriesInput;
};

export type ChatRoomWhereInput = {
  AND?: InputMaybe<Array<ChatRoomWhereInput>>;
  ChatHistories?: InputMaybe<ChatHistoryListRelationFilter>;
  NOT?: InputMaybe<Array<ChatRoomWhereInput>>;
  OR?: InputMaybe<Array<ChatRoomWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  createdBy?: InputMaybe<StringFilter>;
  deletedAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
};

export type ChatRoomWhereUniqueInput = {
  id?: InputMaybe<Scalars['String']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type DateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EnumMessageRoleFieldUpdateOperationsInput = {
  set?: InputMaybe<MessageRole>;
};

export type EnumMessageRoleFilter = {
  equals?: InputMaybe<MessageRole>;
  in?: InputMaybe<Array<MessageRole>>;
  not?: InputMaybe<NestedEnumMessageRoleFilter>;
  notIn?: InputMaybe<Array<MessageRole>>;
};

export type EnumMessageRoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMessageRoleFilter>;
  _min?: InputMaybe<NestedEnumMessageRoleFilter>;
  equals?: InputMaybe<MessageRole>;
  in?: InputMaybe<Array<MessageRole>>;
  not?: InputMaybe<NestedEnumMessageRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<MessageRole>>;
};

export enum MessageRole {
  Assistant = 'assistant',
  System = 'system',
  User = 'user'
}

export type Mutation = {
  createChatRoom?: Maybe<ChatRoom>;
  createOneChatHistory: ChatHistory;
  createOneChatRoom: ChatRoom;
  deleteManyChatHistory?: Maybe<BatchPayload>;
  deleteManyChatRoom?: Maybe<BatchPayload>;
  deleteOneChatHistory?: Maybe<ChatHistory>;
  deleteOneChatRoom?: Maybe<ChatRoom>;
  sendChat: ChatRoom;
  updateManyChatHistory?: Maybe<BatchPayload>;
  updateManyChatRoom?: Maybe<BatchPayload>;
  updateOneChatHistory: ChatHistory;
  updateOneChatRoom: ChatRoom;
  upsertOneChatHistory?: Maybe<ChatHistory>;
  upsertOneChatRoom?: Maybe<ChatRoom>;
};


export type MutationCreateChatRoomArgs = {
  data: ChatRoomSimpleCreateInput;
};


export type MutationCreateOneChatHistoryArgs = {
  data: ChatHistoryCreateInput;
};


export type MutationCreateOneChatRoomArgs = {
  data: ChatRoomCreateInput;
};


export type MutationDeleteManyChatHistoryArgs = {
  where?: InputMaybe<ChatHistoryWhereInput>;
};


export type MutationDeleteManyChatRoomArgs = {
  where?: InputMaybe<ChatRoomWhereInput>;
};


export type MutationDeleteOneChatHistoryArgs = {
  where: ChatHistoryWhereUniqueInput;
};


export type MutationDeleteOneChatRoomArgs = {
  where: ChatRoomWhereUniqueInput;
};


export type MutationSendChatArgs = {
  data: ChatHistorySimpleCreateInput;
};


export type MutationUpdateManyChatHistoryArgs = {
  data: ChatHistoryUpdateManyMutationInput;
  where?: InputMaybe<ChatHistoryWhereInput>;
};


export type MutationUpdateManyChatRoomArgs = {
  data: ChatRoomUpdateManyMutationInput;
  where?: InputMaybe<ChatRoomWhereInput>;
};


export type MutationUpdateOneChatHistoryArgs = {
  data: ChatHistoryUpdateInput;
  where: ChatHistoryWhereUniqueInput;
};


export type MutationUpdateOneChatRoomArgs = {
  data: ChatRoomUpdateInput;
  where: ChatRoomWhereUniqueInput;
};


export type MutationUpsertOneChatHistoryArgs = {
  create: ChatHistoryCreateInput;
  update: ChatHistoryUpdateInput;
  where: ChatHistoryWhereUniqueInput;
};


export type MutationUpsertOneChatRoomArgs = {
  create: ChatRoomCreateInput;
  update: ChatRoomUpdateInput;
  where: ChatRoomWhereUniqueInput;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedDateTimeNullableFilter>;
  _min?: InputMaybe<NestedDateTimeNullableFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedDateTimeWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedDateTimeFilter>;
  _min?: InputMaybe<NestedDateTimeFilter>;
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumMessageRoleFilter = {
  equals?: InputMaybe<MessageRole>;
  in?: InputMaybe<Array<MessageRole>>;
  not?: InputMaybe<NestedEnumMessageRoleFilter>;
  notIn?: InputMaybe<Array<MessageRole>>;
};

export type NestedEnumMessageRoleWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedEnumMessageRoleFilter>;
  _min?: InputMaybe<NestedEnumMessageRoleFilter>;
  equals?: InputMaybe<MessageRole>;
  in?: InputMaybe<Array<MessageRole>>;
  not?: InputMaybe<NestedEnumMessageRoleWithAggregatesFilter>;
  notIn?: InputMaybe<Array<MessageRole>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['DateTime']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type Query = {
  aggregateChatHistory?: Maybe<AggregateChatHistory>;
  aggregateChatRoom?: Maybe<AggregateChatRoom>;
  findFirstChatHistory?: Maybe<ChatHistory>;
  findFirstChatRoom?: Maybe<ChatRoom>;
  findManyChatHistory?: Maybe<Array<ChatHistory>>;
  findManyChatHistoryCount: Scalars['Int'];
  findManyChatRoom?: Maybe<Array<ChatRoom>>;
  findManyChatRoomCount: Scalars['Int'];
  findUniqueChatHistory?: Maybe<ChatHistory>;
  findUniqueChatRoom?: Maybe<ChatRoom>;
  getChatRooms: ChatRoomList;
};


export type QueryAggregateChatHistoryArgs = {
  cursor?: InputMaybe<ChatHistoryWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ChatHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatHistoryWhereInput>;
};


export type QueryAggregateChatRoomArgs = {
  cursor?: InputMaybe<ChatRoomWhereUniqueInput>;
  orderBy?: InputMaybe<Array<InputMaybe<ChatRoomOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatRoomWhereInput>;
};


export type QueryFindFirstChatHistoryArgs = {
  cursor?: InputMaybe<ChatHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ChatHistoryScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ChatHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatHistoryWhereInput>;
};


export type QueryFindFirstChatRoomArgs = {
  cursor?: InputMaybe<ChatRoomWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ChatRoomScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ChatRoomOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatRoomWhereInput>;
};


export type QueryFindManyChatHistoryArgs = {
  cursor?: InputMaybe<ChatHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ChatHistoryScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ChatHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatHistoryWhereInput>;
};


export type QueryFindManyChatHistoryCountArgs = {
  cursor?: InputMaybe<ChatHistoryWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ChatHistoryScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ChatHistoryOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatHistoryWhereInput>;
};


export type QueryFindManyChatRoomArgs = {
  cursor?: InputMaybe<ChatRoomWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ChatRoomScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ChatRoomOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatRoomWhereInput>;
};


export type QueryFindManyChatRoomCountArgs = {
  cursor?: InputMaybe<ChatRoomWhereUniqueInput>;
  distinct?: InputMaybe<Array<InputMaybe<ChatRoomScalarFieldEnum>>>;
  orderBy?: InputMaybe<Array<InputMaybe<ChatRoomOrderByWithRelationInput>>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<ChatRoomWhereInput>;
};


export type QueryFindUniqueChatHistoryArgs = {
  where: ChatHistoryWhereUniqueInput;
};


export type QueryFindUniqueChatRoomArgs = {
  where: ChatRoomWhereUniqueInput;
};


export type QueryGetChatRoomsArgs = {
  keyword?: InputMaybe<Scalars['String']>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StringFieldUpdateOperationsInput = {
  set?: InputMaybe<Scalars['String']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntNullableFilter>;
  _max?: InputMaybe<NestedStringNullableFilter>;
  _min?: InputMaybe<NestedStringNullableFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringWithAggregatesFilter = {
  _count?: InputMaybe<NestedIntFilter>;
  _max?: InputMaybe<NestedStringFilter>;
  _min?: InputMaybe<NestedStringFilter>;
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringWithAggregatesFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum TransactionIsolationLevel {
  ReadCommitted = 'ReadCommitted',
  ReadUncommitted = 'ReadUncommitted',
  RepeatableRead = 'RepeatableRead',
  Serializable = 'Serializable'
}

export type ChatRoomRowFragment = { id: string, name: string, createdAt: any, createdBy: string, ChatHistories: Array<{ id: string, role: MessageRole, message: string, createdAt: any, createdBy?: string | null, chatRoomId: string }> };

export type ChatRoomsRowFragment = { id: string, name: string, createdAt: any, createdBy: string, ChatHistories: Array<{ id: string, createdAt: any, createdBy?: string | null }> };

export type GetChatRoomsQueryVariables = Exact<{
  keyword?: InputMaybe<Scalars['String']>;
}>;


export type GetChatRoomsQuery = { getChatRooms: { total: number, data: Array<{ id: string, name: string, createdAt: any, createdBy: string, ChatHistories: Array<{ id: string, createdAt: any, createdBy?: string | null }> }> } };

export type CreateChatRoomMutationVariables = Exact<{
  data: ChatRoomSimpleCreateInput;
}>;


export type CreateChatRoomMutation = { createChatRoom?: { id: string, name: string, createdAt: any, createdBy: string, ChatHistories: Array<{ id: string, createdAt: any, createdBy?: string | null }> } | null };

export type UpdateOneChatRoomMutationVariables = Exact<{
  data: ChatRoomUpdateInput;
  where: ChatRoomWhereUniqueInput;
}>;


export type UpdateOneChatRoomMutation = { updateOneChatRoom: { id: string, name: string, createdAt: any, createdBy: string, ChatHistories: Array<{ id: string, createdAt: any, createdBy?: string | null }> } };

export type DeleteOneChatRoomMutationVariables = Exact<{
  where: ChatRoomWhereUniqueInput;
}>;


export type DeleteOneChatRoomMutation = { deleteOneChatRoom?: { id: string, name: string, createdAt: any, createdBy: string, ChatHistories: Array<{ id: string, createdAt: any, createdBy?: string | null }> } | null };

export type ChatHistoryRowFragment = { id: string, role: MessageRole, message: string, createdAt: any, createdBy?: string | null, chatRoomId: string };

export type FindUniqueChatRoomQueryVariables = Exact<{
  where: ChatRoomWhereUniqueInput;
}>;


export type FindUniqueChatRoomQuery = { findUniqueChatRoom?: { id: string, name: string, createdAt: any, createdBy: string, ChatHistories: Array<{ id: string, role: MessageRole, message: string, createdAt: any, createdBy?: string | null, chatRoomId: string }> } | null };

export type SendChatMutationVariables = Exact<{
  data: ChatHistorySimpleCreateInput;
}>;


export type SendChatMutation = { sendChat: { id: string, name: string, createdAt: any, createdBy: string, ChatHistories: Array<{ id: string, role: MessageRole, message: string, createdAt: any, createdBy?: string | null, chatRoomId: string }> } };

export const ChatRoomRowFragmentDoc = gql`
    fragment ChatRoomRow on ChatRoom {
  id
  name
  createdAt
  createdBy
  ChatHistories {
    id
    role
    message
    createdAt
    createdBy
    chatRoomId
  }
}
    `;
export const ChatRoomsRowFragmentDoc = gql`
    fragment ChatRoomsRow on ChatRoom {
  id
  name
  createdAt
  createdBy
  ChatHistories {
    id
    createdAt
    createdBy
  }
}
    `;
export const ChatHistoryRowFragmentDoc = gql`
    fragment ChatHistoryRow on ChatHistory {
  id
  role
  message
  createdAt
  createdBy
  chatRoomId
}
    `;
export const GetChatRoomsDocument = gql`
    query getChatRooms($keyword: String) {
  getChatRooms(keyword: $keyword) {
    total
    data {
      ...ChatRoomsRow
    }
  }
}
    ${ChatRoomsRowFragmentDoc}`;

/**
 * __useGetChatRoomsQuery__
 *
 * To run a query within a React component, call `useGetChatRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatRoomsQuery({
 *   variables: {
 *      keyword: // value for 'keyword'
 *   },
 * });
 */
export function useGetChatRoomsQuery(baseOptions?: Apollo.QueryHookOptions<GetChatRoomsQuery, GetChatRoomsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetChatRoomsQuery, GetChatRoomsQueryVariables>(GetChatRoomsDocument, options);
      }
export function useGetChatRoomsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatRoomsQuery, GetChatRoomsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetChatRoomsQuery, GetChatRoomsQueryVariables>(GetChatRoomsDocument, options);
        }
export type GetChatRoomsQueryHookResult = ReturnType<typeof useGetChatRoomsQuery>;
export type GetChatRoomsLazyQueryHookResult = ReturnType<typeof useGetChatRoomsLazyQuery>;
export type GetChatRoomsQueryResult = Apollo.QueryResult<GetChatRoomsQuery, GetChatRoomsQueryVariables>;
export const CreateChatRoomDocument = gql`
    mutation createChatRoom($data: ChatRoomSimpleCreateInput!) {
  createChatRoom(data: $data) {
    ...ChatRoomsRow
  }
}
    ${ChatRoomsRowFragmentDoc}`;
export type CreateChatRoomMutationFn = Apollo.MutationFunction<CreateChatRoomMutation, CreateChatRoomMutationVariables>;

/**
 * __useCreateChatRoomMutation__
 *
 * To run a mutation, you first call `useCreateChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChatRoomMutation, { data, loading, error }] = useCreateChatRoomMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<CreateChatRoomMutation, CreateChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChatRoomMutation, CreateChatRoomMutationVariables>(CreateChatRoomDocument, options);
      }
export type CreateChatRoomMutationHookResult = ReturnType<typeof useCreateChatRoomMutation>;
export type CreateChatRoomMutationResult = Apollo.MutationResult<CreateChatRoomMutation>;
export type CreateChatRoomMutationOptions = Apollo.BaseMutationOptions<CreateChatRoomMutation, CreateChatRoomMutationVariables>;
export const UpdateOneChatRoomDocument = gql`
    mutation UpdateOneChatRoom($data: ChatRoomUpdateInput!, $where: ChatRoomWhereUniqueInput!) {
  updateOneChatRoom(data: $data, where: $where) {
    ...ChatRoomsRow
  }
}
    ${ChatRoomsRowFragmentDoc}`;
export type UpdateOneChatRoomMutationFn = Apollo.MutationFunction<UpdateOneChatRoomMutation, UpdateOneChatRoomMutationVariables>;

/**
 * __useUpdateOneChatRoomMutation__
 *
 * To run a mutation, you first call `useUpdateOneChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOneChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOneChatRoomMutation, { data, loading, error }] = useUpdateOneChatRoomMutation({
 *   variables: {
 *      data: // value for 'data'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpdateOneChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<UpdateOneChatRoomMutation, UpdateOneChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateOneChatRoomMutation, UpdateOneChatRoomMutationVariables>(UpdateOneChatRoomDocument, options);
      }
export type UpdateOneChatRoomMutationHookResult = ReturnType<typeof useUpdateOneChatRoomMutation>;
export type UpdateOneChatRoomMutationResult = Apollo.MutationResult<UpdateOneChatRoomMutation>;
export type UpdateOneChatRoomMutationOptions = Apollo.BaseMutationOptions<UpdateOneChatRoomMutation, UpdateOneChatRoomMutationVariables>;
export const DeleteOneChatRoomDocument = gql`
    mutation deleteOneChatRoom($where: ChatRoomWhereUniqueInput!) {
  deleteOneChatRoom(where: $where) {
    ...ChatRoomsRow
  }
}
    ${ChatRoomsRowFragmentDoc}`;
export type DeleteOneChatRoomMutationFn = Apollo.MutationFunction<DeleteOneChatRoomMutation, DeleteOneChatRoomMutationVariables>;

/**
 * __useDeleteOneChatRoomMutation__
 *
 * To run a mutation, you first call `useDeleteOneChatRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOneChatRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOneChatRoomMutation, { data, loading, error }] = useDeleteOneChatRoomMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteOneChatRoomMutation(baseOptions?: Apollo.MutationHookOptions<DeleteOneChatRoomMutation, DeleteOneChatRoomMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteOneChatRoomMutation, DeleteOneChatRoomMutationVariables>(DeleteOneChatRoomDocument, options);
      }
export type DeleteOneChatRoomMutationHookResult = ReturnType<typeof useDeleteOneChatRoomMutation>;
export type DeleteOneChatRoomMutationResult = Apollo.MutationResult<DeleteOneChatRoomMutation>;
export type DeleteOneChatRoomMutationOptions = Apollo.BaseMutationOptions<DeleteOneChatRoomMutation, DeleteOneChatRoomMutationVariables>;
export const FindUniqueChatRoomDocument = gql`
    query findUniqueChatRoom($where: ChatRoomWhereUniqueInput!) {
  findUniqueChatRoom(where: $where) {
    ...ChatRoomRow
  }
}
    ${ChatRoomRowFragmentDoc}`;

/**
 * __useFindUniqueChatRoomQuery__
 *
 * To run a query within a React component, call `useFindUniqueChatRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUniqueChatRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUniqueChatRoomQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useFindUniqueChatRoomQuery(baseOptions: Apollo.QueryHookOptions<FindUniqueChatRoomQuery, FindUniqueChatRoomQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FindUniqueChatRoomQuery, FindUniqueChatRoomQueryVariables>(FindUniqueChatRoomDocument, options);
      }
export function useFindUniqueChatRoomLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FindUniqueChatRoomQuery, FindUniqueChatRoomQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FindUniqueChatRoomQuery, FindUniqueChatRoomQueryVariables>(FindUniqueChatRoomDocument, options);
        }
export type FindUniqueChatRoomQueryHookResult = ReturnType<typeof useFindUniqueChatRoomQuery>;
export type FindUniqueChatRoomLazyQueryHookResult = ReturnType<typeof useFindUniqueChatRoomLazyQuery>;
export type FindUniqueChatRoomQueryResult = Apollo.QueryResult<FindUniqueChatRoomQuery, FindUniqueChatRoomQueryVariables>;
export const SendChatDocument = gql`
    mutation sendChat($data: ChatHistorySimpleCreateInput!) {
  sendChat(data: $data) {
    ...ChatRoomRow
  }
}
    ${ChatRoomRowFragmentDoc}`;
export type SendChatMutationFn = Apollo.MutationFunction<SendChatMutation, SendChatMutationVariables>;

/**
 * __useSendChatMutation__
 *
 * To run a mutation, you first call `useSendChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendChatMutation, { data, loading, error }] = useSendChatMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useSendChatMutation(baseOptions?: Apollo.MutationHookOptions<SendChatMutation, SendChatMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendChatMutation, SendChatMutationVariables>(SendChatDocument, options);
      }
export type SendChatMutationHookResult = ReturnType<typeof useSendChatMutation>;
export type SendChatMutationResult = Apollo.MutationResult<SendChatMutation>;
export type SendChatMutationOptions = Apollo.BaseMutationOptions<SendChatMutation, SendChatMutationVariables>;