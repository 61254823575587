import React from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Theme } from "@mui/material/styles";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme: Theme) => ({
  backdrop: {
    zIndex: theme.zIndex?.drawer + 1,
    color: "#fff",
  },
}));

export const LoadingBackdrop: React.FC = () => {
  const classes = useStyles();
  return (
    <Backdrop className={classes.backdrop} open={true} invisible={true}>
      <CircularProgress color="primary" />
    </Backdrop>
  );
};
