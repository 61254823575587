import { Fragment } from "react";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useGlobalAlertDialog } from "../../hooks";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import { createdByToName, trimLongText } from "../../utils/helper";
import moment from "moment";
import { displayDateFormat } from "../../utils/const";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  ChatRoomFormDialog,
  ChatRoomFormDialogStatus,
} from "./chat-room-form-dialog";
import { useParams } from "react-router-dom";
import { useChatRoomsList } from "../../hooks/chat-rooms-hook";
import { Link } from 'react-router-dom';

const useStyles = makeStyles(() =>
  createStyles({
    ListItem: {
      // paddingTop: "20px",
    },
    Link: {
      textDecoration: "none",
      width: "100%",
      color: "black",
    },
    fullHeightList: {
      height: "100% !important",
    },
  })
);

export const ChatRoomList = () => {
  const classes = useStyles();
  const { displayGlobalAlertDialog } = useGlobalAlertDialog();
  const { chatRooms, editChatRoom, deleteChatRoom, createNewChatRoom } =
    useChatRoomsList();

  const routerParams = useParams();
  const chatRoomId = routerParams.chatRoomId ?? "";

  function renderRow(props: ListChildComponentProps) {
    const { index, style } = props;
    const chatRoom = chatRooms[index];

    const lastUpdatedAt =
      chatRoom.ChatHistories.length > 0 && chatRoom.ChatHistories[0]?.createdAt
        ? moment(chatRoom.ChatHistories[0].createdAt)
        : moment(chatRoom.createdAt);

    const lastUpdateBy =
      chatRoom.ChatHistories.length > 0 && chatRoom.ChatHistories[0]?.createdBy
        ? chatRoom.ChatHistories[0].createdBy
        : chatRoom.createdBy;

    return (
      <ListItem
        style={style}
        key={index}
        component="div"
        disablePadding
        className={classes.ListItem}
        secondaryAction={
          <div
            style={{
              margin: "10px 0",
            }}
          >
            <ChatRoomFormDialog
              onSubmit={async function (name: string): Promise<boolean> {
                return await editChatRoom(chatRoom.id, name);
              }}
              mode={ChatRoomFormDialogStatus.edit}
              defaultValue={chatRoom.name}
              buttonProps={{
                color: "primary",
                size: "small",
              }}
            />
            <IconButton
              size="small"
              onClick={() => {
                displayGlobalAlertDialog({
                  title: `Delete Chat Room: ${chatRoom.name}`,
                  content: "Are you sure you want to delete this chat room?",
                  handleConfirm: () => {
                    deleteChatRoom(chatRoom.id);
                  },
                });
              }}
            >
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </div>
        }
      >
          <ListItemButton divider={true} selected={chatRoom.id === chatRoomId} 
          component={Link}
          to={`/${chatRoom.id}`}
          >
            <ListItemText
              primary={trimLongText(chatRoom.name, 25)}
              primaryTypographyProps={{ fontSize: 20 }}
              secondary={
                <Fragment>
                  <Typography component="span" variant="body2" fontSize={10}>
                    {createdByToName(lastUpdateBy)}
                  </Typography>
                  {lastUpdatedAt && (
                    <Typography component="span" variant="body2" fontSize={10}>
                      {` - ${lastUpdatedAt.format(displayDateFormat)}`}
                    </Typography>
                  )}
                </Fragment>
              }
            />
          </ListItemButton>
      </ListItem>
    );
  }

  return (
    <Paper
      sx={{
        width: "100%",
        maxWidth: 400,
      }}
    >
      <Grid container style={{ padding: "0 20px" }}>
        <Grid item xs={8}>
          <h4>Chat Rooms</h4>
        </Grid>
        <Grid item xs={4}>
          <ChatRoomFormDialog
            onSubmit={createNewChatRoom}
            buttonProps={{
              style: {
                margin: "15px 0",
                float: "right",
              },
              color: "primary",
              size: "small",
            }}
          />
        </Grid>
      </Grid>
      <Divider></Divider>
      <Box
        sx={{
          width: "100%",
          height: "80vh",
        }}
      >
        <FixedSizeList
          height={400}
          itemData={chatRooms}
          itemSize={80}
          itemCount={chatRooms.length}
          overscanCount={5}
          className={classes.fullHeightList}
        >
          {renderRow}
        </FixedSizeList>
      </Box>
    </Paper>
  );
};
