import Cookies from "js-cookie";
import { decodeToken } from "@avista-jwt/client";
import { JwtPayload } from "@avista-jwt/common";
import { SERVER, chatgptDisplayIconText, chatgptDisplayName } from "../const";

const createdByToName = (createdBy: string | null | undefined) => {
  if (!createdBy) return chatgptDisplayName;
  if (jwtPayload && createdBy === jwtPayload.email) return jwtPayload?.name;

  return createdBy;
};

const getUserIconText = (createdBy: string | null | undefined) => {
  if (!createdBy) return chatgptDisplayIconText;
  const userName = createdByToName(createdBy);
  if (userName.lastIndexOf(" ") > -1) {
    return userName
      .split(".")
      .map((n) => n[0])
      .join("")
      .substring(0, 2)
      .toUpperCase();
  }
  return userName;
};

const jwtPayload = ((): JwtPayload => {
  const token = Cookies.get(SERVER.JWT_COOKIE_NAME);
  const payload = decodeToken(token || "") as JwtPayload;
  return payload;
})();

const trimLongText = (text: string, maxLength: number) => {
  if (text.length > maxLength) {
    return `${text.substring(0, maxLength)}...`;
  }
  return text;
};

export { createdByToName, getUserIconText, jwtPayload, trimLongText };
