import { ReducerActionType } from "../utils/interface";
import { IReducerState, IReducerActions } from "../utils/interface";
import { MainContext } from "../provider/MainContext";
import { useContext } from "react";

export const initialState: IReducerState = {
  snackbar: {
    text: "",
    isOpen: false,
    type: undefined,
    duration: 2000,
    handleClose: () => {},
  },
  globalAlertDialog: {
    isOpen: false,
    title: "Alert",
    content: "",
    confirmBtnText: "Confirm",
    cancelBtnText: "Cancel",
    handleCancel: () => {},
    handleConfirm: () => {},
  },
  chatRooms: [],
};

export const reducer = (
  state: IReducerState,
  action: IReducerActions
): IReducerState => {
  switch (action.type) {
    case ReducerActionType.hideSnackbar:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          isOpen: false,
        },
      };
    case ReducerActionType.showSnackbar:
      return {
        ...state,
        snackbar: {
          isOpen: true,
          text: action.payload?.snackbar?.text,
          type: action.payload?.snackbar?.type,
          handleClose:
            action.payload?.snackbar?.handleClose ??
            initialState.snackbar.handleClose,
        },
      };
    case ReducerActionType.showGlobalAlertDialog:
      return {
        ...state,
        globalAlertDialog: {
          isOpen: true,
          title:
            action.payload?.globalAlertDialog?.title ??
            initialState.globalAlertDialog.title,
          content:
            action.payload?.globalAlertDialog?.content ??
            initialState.globalAlertDialog.content,
          confirmBtnText:
            action.payload?.globalAlertDialog?.confirmBtnText ??
            initialState.globalAlertDialog.confirmBtnText,
          cancelBtnText:
            action.payload?.globalAlertDialog?.cancelBtnText ??
            initialState.globalAlertDialog.cancelBtnText,
          handleCancel:
            action.payload?.globalAlertDialog?.handleCancel ??
            initialState.globalAlertDialog.handleCancel,
          handleConfirm:
            action.payload?.globalAlertDialog?.handleConfirm ??
            initialState.globalAlertDialog.handleConfirm,
        },
      };
    case ReducerActionType.hideGlobalAlertDialog:
      return {
        ...state,
        globalAlertDialog: {
          ...state.globalAlertDialog,
          isOpen: false,
        },
      };

    case ReducerActionType.updateChatRooms:
      return {
        ...state,
        chatRooms: action.payload?.chatRooms ?? initialState.chatRooms,
      };
    default:
      throw new Error();
  }
};
export const useMainContext = () => {
  const reducer = useContext(MainContext);
  return reducer;
};

export { ReducerActionType };
