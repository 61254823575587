import { ChatRoomsRowFragment } from "../../generated/graphql";

type ISnackbarType = "success" | "error" | "info" | "warning";

type IReducerActions = {
  type: ReducerActionType;
  payload?: Partial<IReducerState>;
};
interface IReducerState {
  snackbar: Partial<ISnackbar>;
  globalAlertDialog: Partial<IGlobalAlertDialog>;
  chatRooms: IChatRooms;
}

interface ISnackbar {
  text: string;
  isOpen: boolean;
  type: ISnackbarType;
  duration?: number;
  handleClose: () => void
}
interface IGlobalAlertDialog{
  isOpen: boolean
  title: string;
  content: string;
  confirmBtnText?: string
  cancelBtnText?: string
  handleCancel?: () => void
  handleConfirm?: () => void
}

type IChatRooms = ChatRoomsRowFragment[];

enum ReducerActionType {
  showSnackbar = "showSnackbar",
  hideSnackbar = "hideSnackbar",
  showGlobalAlertDialog = "showGlobalAlertDialog",
  hideGlobalAlertDialog = "hideGlobalAlertDialog",
  updateChatRooms = "updateChatRooms",
  updateCurrentChatRoom = "updateCurrentChatRoom",
}

interface IContextProps {
  state: IReducerState;
  dispatch: React.Dispatch<IReducerActions>;
}
export { ReducerActionType };
export type {
  ISnackbarType,
  ISnackbar,
  IGlobalAlertDialog,
  IReducerState,
  IReducerActions,
  IContextProps,
};
