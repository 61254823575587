import { useCallback, useMemo } from "react";
import {
  IGlobalAlertDialog,
  IReducerActions,
  IReducerState,
  ISnackbarType,
  ReducerActionType,
} from "../utils/interface";
import { useMainContext } from "../reducer";
import {
  useCreateChatRoomMutation,
  useDeleteOneChatRoomMutation,
  useFindUniqueChatRoomLazyQuery,
  useGetChatRoomsLazyQuery,
  useSendChatMutation,
  useUpdateOneChatRoomMutation,
} from "../generated/graphql";

const useApi = () => {
  // const client = useApolloClient();

  const [getChatRooms, getChatRoomsQueryResult] = useGetChatRoomsLazyQuery({
    fetchPolicy: "no-cache",
  });

  const [createChatRoom, createChatRoomMutationResult] =
    useCreateChatRoomMutation();

  const [updateChatRoom, updateChatRoomMutationResult] =
    useUpdateOneChatRoomMutation();

  const [deleteChatRoom, deleteChatRoomMutationResult] =
    useDeleteOneChatRoomMutation();

  const [getChatRoomAndHistories, getChatRoomAndHistoriesQueryResult] =
    useFindUniqueChatRoomLazyQuery({ fetchPolicy: "no-cache" });

  const [sendChat, sendChatMutationResult] = useSendChatMutation();

  return useMemo(
    () => ({
      getChatRooms,
      createChatRoom,
      updateChatRoom,
      deleteChatRoom,
      getChatRoomAndHistories,
      sendChat,
      getChatRoomsQueryResult,
      createChatRoomMutationResult,
      updateChatRoomMutationResult,
      deleteChatRoomMutationResult,
      getChatRoomAndHistoriesQueryResult,
      sendChatMutationResult,
    }),
    [
      createChatRoom,
      createChatRoomMutationResult,
      deleteChatRoom,
      deleteChatRoomMutationResult,
      getChatRoomAndHistories,
      getChatRoomAndHistoriesQueryResult,
      getChatRooms,
      getChatRoomsQueryResult,
      sendChat,
      sendChatMutationResult,
      updateChatRoom,
      updateChatRoomMutationResult,
    ]
  );
};

const useSnackbar = () => {
  // get reducer from context
  const {
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const displaySnackbar = useCallback(
    (snackbarType: ISnackbarType, message: string) => {
      dispatch({
        type: ReducerActionType.showSnackbar,
        payload: {
          snackbar: {
            text: message,
            type: snackbarType,
            handleClose: () => {
              dispatch({
                type: ReducerActionType.hideSnackbar,
              });
            },
          },
        },
      });
    },
    [dispatch]
  );

  return { displaySnackbar };
};

const useGlobalAlertDialog = () => {
  // get reducer from context
  const {
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  const displayGlobalAlertDialog = useCallback(
    (props: Omit<IGlobalAlertDialog, "isOpen">) => {
      dispatch({
        type: ReducerActionType.showGlobalAlertDialog,
        payload: {
          globalAlertDialog: {
            ...props,
            handleCancel: () => {
              if (props.handleCancel) props.handleCancel();
              dispatch({
                type: ReducerActionType.hideGlobalAlertDialog,
              });
            },
            handleConfirm: () => {
              if (props.handleConfirm) props.handleConfirm();
              dispatch({
                type: ReducerActionType.hideGlobalAlertDialog,
              });
            },
          },
        },
      });
    },
    [dispatch]
  );

  return { displayGlobalAlertDialog };
};

export { useApi, useSnackbar, useGlobalAlertDialog };
