
interface NavItem {
  name: string;
  url: string;
}

const navItems: NavItem[] = [
  { name: "ChatAVA", url: "/" },
];

export { navItems };
