import { Container, Grid, Paper, Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { Chat } from "../../components/chat/chat";
import { ChatRoomList } from "../../components/chat-room/chat-room-list";
import { useParams } from "react-router-dom";
import { jwtPayload } from "../../utils/helper";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sideBarCoitainer: {
      "&>div": {
        float: "right",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },
  })
);
export const ChatRoom = () => {
  const routerParams = useParams();
  const chatRoomId = routerParams.chatRoomId ?? "";
  const user = jwtPayload?.email;
  const classes = useStyles();

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={5} md={3} className={classes.sideBarCoitainer}>
          <ChatRoomList />
        </Grid>
        <Grid item xs={12} sm={7} md={9}>
          <Chat chatRoomId={chatRoomId} user={user} />
        </Grid>
      </Grid>
    </>
  );
};
