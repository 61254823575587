import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useTheme } from "@mui/material/styles";
import Container from "@mui/material/Container";
import {
  Button,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  IconButton,
  Theme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AvistaAppBar } from "@avista-components/avista-app-bar";
import { AvistaSnackbar } from "@avista-components/avista-snackbar";
import { NavDrawer } from "./NavDrawer";
import { navItems } from "./navItems";
import { SERVER } from "../utils/const";
import { AvistaAvatar } from "@avista-components/avista-avatar";
import {
  IReducerActions,
  IReducerState,
  ReducerActionType,
} from "../utils/interface";
import { useMainContext } from "../reducer";
import MenuIcon from "@mui/icons-material/Menu";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
  },
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  appBarSpacer: theme.mixins.toolbar,
  container: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    width: "95vw",
    maxWidth: "95vw",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 99,
  },
  input: {
    display: "flex",
    padding: 0,
    height: "60px",
    "& .MuiInput-input": {
      display: "flex",
      padding: 0,
    },
  },
  menuIcon: {
    zIndex: 10000,
    position: "fixed",
    top: 0,
    left: 0,
    backgroundColor: theme.palette.primary.main + " !important",
    
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

const Layout = (props: React.PropsWithChildren) => {
  const [isOpenDrawer, setIsOpenDrawer] = React.useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();

  // get reducer from context
  const {
    state,
    dispatch,
  }: { state: IReducerState; dispatch: React.Dispatch<IReducerActions> } =
    useMainContext();

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AvistaAppBar
        children={{
          mainChildrenItems: navItems,
          rightChildren: (
            <>
              <AvistaAvatar
                loginServerUrl={SERVER.LOGIN_HOST!}
                myProfileUrl={SERVER.ADMIN_PROFILE_URL!}
              />
            </>
          ),
        }}
        options={{
          appBarStyle: classes.appBar,
        }}
      />

      <IconButton
        // variant="circular"
        size="large"
        className={classes.menuIcon}
        onClick={() => {
          setIsOpenDrawer(!isOpenDrawer);
        }}
      >
        <MenuIcon htmlColor="white" />
      </IconButton>
      <NavDrawer
        open={isOpenDrawer}
        onClose={() => {
          setIsOpenDrawer(false);
        }}
        style={{ zIndex: theme.zIndex.drawer + 98 }}
        setIsOpenDrawer={setIsOpenDrawer}
      />
      <main>
        <div className={classes.appBarSpacer} />
        <Container
          maxWidth="xl"
          className={classes.container}
          children={props.children as NonNullable<React.ReactNode>}
        ></Container>

        <AvistaSnackbar
          isOpen={state.snackbar.isOpen!}
          text={state.snackbar.text!}
          type={state.snackbar.type!}
          handleClose={state.snackbar.handleClose!}
        />

        <Dialog
          open={state.globalAlertDialog.isOpen!}
          onClose={state.globalAlertDialog.handleCancel}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {state.globalAlertDialog.title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {state.globalAlertDialog.content}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={state.globalAlertDialog.handleCancel}>
              {state.globalAlertDialog.cancelBtnText}
            </Button>
            <Button onClick={state.globalAlertDialog.handleConfirm} autoFocus>
              {state.globalAlertDialog.confirmBtnText}
            </Button>
          </DialogActions>
        </Dialog>
      </main>
    </div>
  );
};

export { useStyles, Layout };
